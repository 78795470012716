<template>
    <div class="base-container">
        <el-card class="box-card">
            <div class="item">
                <ul class="table-area" v-if="dataList.length>0">
                    <li class="grid-item" v-for="(item, index) in dataList" :key="index">
                        <div class="grid-box">
                            <div class="grid-content" @click="openGiveMark(item, index, $event)">
                                <el-image class="work-img" fit="contain" :src="$store.getters.getFilePath+item.images" lazy/>
                                <div class="rate-sign">{{item.grade}}</div>
                                <div class="work-content">
                                    <div>作品编号：{{item.number}}</div>
                                    <!--<div>精度：{{item.precision}}</div>-->
                                    <div class="py_btn" @click="toGiveMark(item, index, $event)">评阅</div>
                                </div>
                                <div class="btn-area">
                                    <i @click="updateYjOrderHandle($event, 'top', item)"
                                       class="icon el-icon-upload2"></i>
                                    <i @click="updateYjOrderHandle($event, 'stick', item)"
                                       class="icon el-icon-download"></i>
                                    <i @click="updateYjOrderHandle($event, 'left', item)"
                                       class="icon icon-circle el-icon-arrow-up"></i>
                                    <i @click="updateYjOrderHandle($event, 'right', item)"
                                       class="icon icon-circle el-icon-arrow-down"></i>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                <el-empty v-else class="empty-area" description="暂无已评阅记录"></el-empty>
                <el-pagination
                        v-if="total>0"
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="query.start"
                        :page-sizes="[8, 12]"
                        :page-size="query.size"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total">
                </el-pagination>
            </div>
        </el-card>

        <GiveMarkDialog
                :isTypeFu="2"
                :queryObjFu="queryGMObj"
                :dialogVisibleFu="dialogGMVisible"
                @closeDialog="closeDialog"
        />
    </div>
</template>

<script>
    import {getStudentScoreList, getStudentScoreList2, updateYjOrder, updateYjOrder2} from "../../api/score";
    import GiveMarkDialog from "../../components/GiveMarkDialog";

    export default {
        name: "scoreRote",
        components: {GiveMarkDialog},
        data() {
            return {
                query: {
                    start: 1,
                    size: 8,
                    order: 1,
                    scores: null,
                },
                total: 0,
                dataList: [],
                isChecked: false,// 置顶、置底、上移、下移 防重复点击

                dialogGMVisible: false,
                queryGMObj: {},
                startCutDown: false, // true-评阅完成页数需要减一
            }
        },
        created() {
            let arr = this.$route.path.split('/');
            this.query.scores = arr[arr.length - 1];
            let start = window.localStorage.getItem('score_start_' + this.query.scores);
            let size = window.localStorage.getItem('score_size_' + this.query.scores);
            if (start && start != 0) {
                this.query.start = Number(start);
            }
            if (size && size != 0) {
                this.query.size = Number(size);
            }
            this.getList();
        },
        methods: {
            // 置顶
            updateYjOrderHandle(e, status, data) {
                e.stopPropagation();
                if (this.isChecked) {
                    return;
                }
                this.isChecked = true;

                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestOrderHandle(updateYjOrder, data, status)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestOrderHandle(updateYjOrder2, data, status)
                }

            },
            // 排序公共请求方法
            requestOrderHandle(api, data, status) {
                let obj = {
                    assess_num: data.assess_num,
                    id_card: data.id_card,
                    order: status,
                }
                api(obj)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.isChecked = false;
                            return this.$message.error(res.data.message);
                        }
                        this.$message.success(res.data.message);
                        this.isChecked = false;
                        this.getList();
                    })
                    .catch(err => {
                        console.log(err)
                        this.isChecked = false;
                    })
            },
            // 关闭评阅弹框
            closeDialog(val, type) {
                this.dialogGMVisible = val;
                if (type) {
                    if (this.startCutDown) {
                        this.query.start--;
                        window.localStorage.setItem('score_start_' + this.query.scores, this.query.start);
                    }
                    this.getList();
                }
            },
            // 去评阅-图片
            openGiveMark(row, idx, event) {
                event.preventDefault();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                this.queryGMObj = {
                    start: start,
                    order: this.query.order,
                    scores: this.query.scores,
                }
                this.dialogGMVisible = true;
            },
            // 去评阅-按钮
            toGiveMark(data, idx, event) {
                event.stopPropagation();
                let start = (this.query.start - 1) * this.query.size + (idx + 1);
                this.$router.push({
                    path: '/score/giveMark',
                    query: {
                        start: start,
                        order: this.query.order,
                        scores: this.query.scores,
                    }
                });
                // window.localStorage.setItem('score_start_' + this.query.scores, '1');
                // window.localStorage.setItem('score_size_' + this.query.scores, '8');
                window.localStorage.setItem('score_size_' + this.query.scores, this.query.size);
            },
            handleSizeChange(val) {
                this.query.size = val;
                window.localStorage.setItem('score_size_' + this.query.scores, this.query.size);
                this.getList();
            },
            handleCurrentChange(val) {
                this.query.start = val;
                window.localStorage.setItem('score_start_' + this.query.scores, this.query.start);
                this.getList();
            },
            getList() {
                if (this.$store.state.teacherType == 1) {
                    // 阅卷老师
                    this.requestHandle(getStudentScoreList)
                } else if (this.$store.state.teacherType == 3) {
                    // 第二轮
                    this.requestHandle(getStudentScoreList2)
                }
            },
            requestHandle(api) {
                this.dataList = [];
                api(this.query)
                    .then(res => {
                        console.log(res)
                        if (res.data.code != 200) {
                            this.dataList = [];
                            this.total = 0;
                            return this.$message.error(res.data.message)
                        }
                        this.dataList = res.data.data.list;
                        this.total = res.data.data.sum;

                        if (this.query.start > 1 && this.dataList.length == 1) {
                            this.startCutDown = true;
                        } else {
                            this.startCutDown = false;
                        }
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
        }
    }
</script>

<style scoped lang="scss">
    @import "../../assets/css/list";
</style>
